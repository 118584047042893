
import { ref, defineComponent, PropType } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import IconBurgerLines from '@/components/UI/Icons/IconBurgerLines.vue';
import { UserProfileFragment } from '@/api/generated/portal_api';
import { TransportationsRouteTypes } from '@/router/constants';

export default defineComponent({
  name: 'Header',
  components: {
    IconBurgerLines,
  },
  props: {
    type: {
      type: String as PropType<TransportationsRouteTypes>,
      required: true,
    },
    profile: {
      type: Object as PropType<UserProfileFragment>,
    },
  },
  setup() {
    const router = useRouter();
    const userMenuPopoverVisible = ref(false);

    function toggleUserMenuPopover() {
      userMenuPopoverVisible.value = !userMenuPopoverVisible.value;
    }

    function logout() {
      router.push({
        name: 'logout',
      });
    }

    const { t } = useI18n({
      messages: {
        ru: {
          active: 'Active',
          completed: 'Completed',
          exit: 'Log out',
        },
      },
    });

    return {
      t,
      TransportationsRouteTypes,
      userMenuPopoverVisible,
      logout,
      toggleUserMenuPopover,
    };
  },
});

import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3906f560"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_row_label_value = _resolveComponent("row-label-value")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_row_label_value, {
        label: _ctx.t('driverName')
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.transportation.driver.fullName || _ctx.t('none')), 1)
        ]),
        _: 1
      }, 8, ["label"]),
      (_ctx.transportation.driver.phoneNumbers && _ctx.transportation.driver.phoneNumbers[0])
        ? (_openBlock(), _createBlock(_component_row_label_value, {
            key: 0,
            label: _ctx.t(_ctx.transportation.driver.phoneNumbers.length > 1 ? 'driverPhones' : 'driverPhone')
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.transportation.driver.phoneNumbers.join(', ')), 1)
            ]),
            _: 1
          }, 8, ["label"]))
        : _createCommentVNode("", true)
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.transportation.vehicles, (vehicle, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "block"
      }, [
        _createVNode(_component_row_label_value, {
          label: _ctx.t('vehicleType')
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.convertGlossaryValuesToLabelString(vehicle.type, _ctx.glossaries.vehicleTypes.labelsByValue) ||
        _ctx.t('none')), 1)
          ]),
          _: 2
        }, 1032, ["label"]),
        _createVNode(_component_row_label_value, {
          label: _ctx.t('vehicleTypesOfBody')
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.convertGlossaryValuesToLabelString(
          vehicle.typesOfBody,
          _ctx.glossaries.bodyTypes.labelsByValue,
        ) || _ctx.t('none')), 1)
          ]),
          _: 2
        }, 1032, ["label"]),
        _createVNode(_component_row_label_value, {
          label: _ctx.t('vehicleModel')
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(vehicle.brand || _ctx.t('none')), 1)
          ]),
          _: 2
        }, 1032, ["label"]),
        _createVNode(_component_row_label_value, {
          label: _ctx.t('vehicleNumber')
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(vehicle.numberPlate || _ctx.t('none')), 1)
          ]),
          _: 2
        }, 1032, ["label"])
      ]))
    }), 128))
  ], 64))
}

import { defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import { TransportationFragment } from '@/api/generated/portal_api';
import { ParsedGlossaries } from '@/stores/almanac';
import { convertGlossaryValuesToLabelString } from '@/utils/convertGlossaryValuesToLabelString';
import RowLabelValue from '@/components/requests/RowLabelValue.vue';

export default defineComponent({
  name: 'DriverAndVehicle',
  components: {
    RowLabelValue,
  },
  props: {
    transportation: {
      type: Object as PropType<TransportationFragment>,
      required: true,
    },
    glossaries: {
      type: Object as PropType<ParsedGlossaries>,
      required: true,
    },
  },
  setup() {
    const { t } = useI18n({
      messages: {
        ru: {
          driverName: 'Driver name',
          driverPhone: 'Driver phone',
          driverPhones: 'Driver phone',
          vehicleType: 'Vehicle type',
          vehicleTypesOfBody: 'Trailer type',
          vehicleModel: 'Model',
          vehicleNumber: 'Plate number',
          none: 'не указан',
        },
      },
    });

    return {
      t,
      convertGlossaryValuesToLabelString,
    };
  },
});


import { defineComponent, ref, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import IconTimeMark from '@/components/UI/Icons/IconTimeMark.vue';
import { TransportationsSummaryFragment } from '@/api/generated/portal_api';

export default defineComponent({
  name: 'RequestsTabs',
  components: {
    IconTimeMark,
  },
  props: {
    transportationsCountByType: {
      type: Object as PropType<TransportationsSummaryFragment>,
    },
    transportationsCountLoading: Boolean,
  },
  setup() {
    const { t } = useI18n({
      messages: {
        ru: {
          late: 'Missed appointment',
          inTime: 'On time',
          upcoming: 'Planned',
          noInitData: 'Not tracking',
        },
      },
    });

    const typeKeys = ref<(keyof TransportationsSummaryFragment)[]>([
      'late',
      'noInitData',
      'inTime',
      'upcoming',
    ]);

    return {
      t,
      typeKeys,
    };
  },
});

import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b00c2a4a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }
const _hoisted_2 = {
  key: 0,
  class: "userMenuPopover"
}
const _hoisted_3 = { class: "userName" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_icon_burger_lines = _resolveComponent("icon-burger-lines")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_link, {
      class: _normalizeClass(["link", { active: _ctx.type === _ctx.TransportationsRouteTypes.active }]),
      to: {
        name: 'transportations',
        params: { type: _ctx.TransportationsRouteTypes.active },
      }
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t('active')), 1)
      ]),
      _: 1
    }, 8, ["class", "to"]),
    _createVNode(_component_router_link, {
      class: _normalizeClass(["link", { active: _ctx.type === _ctx.TransportationsRouteTypes.completed }]),
      to: {
        name: 'transportations',
        params: { type: _ctx.TransportationsRouteTypes.completed },
      }
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t('completed')), 1)
      ]),
      _: 1
    }, 8, ["class", "to"]),
    (_ctx.profile && _ctx.profile && _ctx.profile.id)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("button", {
            class: "userMenuButton",
            onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleUserMenuPopover && _ctx.toggleUserMenuPopover(...args)), ["stop"]))
          }, [
            _createVNode(_component_icon_burger_lines)
          ]),
          (_ctx.userMenuPopoverVisible)
            ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.profile.title), 1),
                _createElementVNode("a", {
                  href: "",
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.logout && _ctx.logout(...args)))
                }, _toDisplayString(_ctx.t('exit')), 1)
              ])), [
                [_directive_click_outside, _ctx.toggleUserMenuPopover]
              ])
            : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true)
  ]))
}
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-40bbdeb4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "icon" }
const _hoisted_2 = { class: "info" }
const _hoisted_3 = { class: "count" }
const _hoisted_4 = { class: "type" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_time_mark = _resolveComponent("icon-time-mark")!
  const _component_wrapper = _resolveComponent("wrapper")!

  return (_openBlock(), _createBlock(_component_wrapper, { class: "tabs" }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.typeKeys, (key, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "tab",
          key: index
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_icon_time_mark, {
              type: key,
              width: 48
            }, null, 8, ["type"])
          ]),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("span", _hoisted_3, [
              (
              !_ctx.transportationsCountLoading &&
              _ctx.transportationsCountByType &&
              typeof _ctx.transportationsCountByType[key] === 'number'
            )
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createTextVNode(_toDisplayString(_ctx.transportationsCountByType[key]), 1)
                  ], 64))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createTextVNode("-")
                  ], 64))
            ]),
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.t(key)), 1)
          ])
        ]))
      }), 128))
    ]),
    _: 1
  }))
}
import { intervalToDuration } from 'date-fns';

function pluralize(count: number, words: string[]) {
  const cases = [2, 0, 1, 1, 1, 2];
  return (
    count + ' ' + words[count % 100 > 4 && count % 100 < 20 ? 2 : cases[Math.min(count % 10, 5)]]
  );
}

export function delayToHumanText(time: number): string {
  if (!time) {
    return '';
  }

  const { months, days, hours, minutes } = intervalToDuration({ start: 0, end: time });

  let text = '';

  if (months) {
    text += pluralize(months, ['month', 'months', 'months']) + ' ';
  }

  if (days) {
    text += pluralize(days, ['day', 'days', 'days']) + ' ';
  }
  if (hours) {
    text += pluralize(hours, ['hour', 'hours', 'hours']) + ' ';
  }

  if (minutes) {
    text += pluralize(minutes, ['minute', 'minutes', 'minutes']) + ' ';
  }

  return text.trim();
}


import { defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import { TransportationFragment } from '@/api/generated/portal_api';
import { ParsedGlossaries } from '@/stores/almanac';
import RowLabelValue from '@/components/requests/RowLabelValue.vue';

export default defineComponent({
  name: 'Expeditor',
  components: {
    RowLabelValue,
  },
  props: {
    transportation: {
      type: Object as PropType<TransportationFragment>,
      required: true,
    },
    glossaries: {
      type: Object as PropType<ParsedGlossaries>,
      required: true,
    },
  },
  setup() {
    const { t } = useI18n({
      messages: {
        ru: {
          name: 'LSS name',
          phone: 'LSS phone',
          none: '',
        },
      },
    });

    return {
      t,
    };
  },
});

import { defineStore } from 'pinia';
import { apiAuth } from '@/api/auth';
import { apiCallWrapper } from './apiWrapper';
import { RouteLocationNormalized } from 'vue-router';
import { useToast } from 'vue-toastification';
import { useProfileStore } from '@/stores/profile';

const toast = useToast();

export const TOKEN_REFRESH_URL = '/token/refresh';

export type AuthTokens = {
  accessToken: string;
  refreshToken: string;
};

export type LoginByEmailParams = {
  email: string;
  password: string;
};

const getTokensFromLocalStorage = (): AuthTokens => {
  return JSON.parse(localStorage.getItem('auth') || '{}') as AuthTokens;
};

type AuthMe = {
  isAnon: boolean;
  phone: string;
  userId: string;
};

type State = {
  isAuthenticated: boolean;
  isRefreshTokensMode: boolean;
  tokensRefreshing: boolean;
  redirectAfterLogin?: RouteLocationNormalized;
  authMeData?: AuthMe;
};

export const useAuthStore = defineStore({
  id: 'auth',

  state: (): State => ({
    isAuthenticated: false,
    isRefreshTokensMode: false,
    tokensRefreshing: false,
    redirectAfterLogin: undefined,
    authMeData: undefined,
  }),

  getters: {
    accessToken() {
      return getTokensFromLocalStorage().accessToken;
    },
    refreshToken() {
      return getTokensFromLocalStorage().refreshToken;
    },
  },

  actions: {
    async refreshTokens(): Promise<AuthTokens | undefined> {
      this.tokensRefreshing = true;
      this.isRefreshTokensMode = true;
      const tokens = await apiCallWrapper<AuthTokens>(apiAuth, {
        method: 'post',
        url: TOKEN_REFRESH_URL,
        data: {
          authPoint: 'portal',
          refreshToken: this.refreshToken,
        },
      });
      this.tokensRefreshing = false;
      this.isRefreshTokensMode = false;

      if (tokens && tokens.accessToken) {
        localStorage.setItem('auth', JSON.stringify(tokens));
        return tokens;
      }
    },

    async getAuthMeData() {
      const me = await apiCallWrapper<AuthMe>(apiAuth, {
        method: 'get',
        url: '/me',
      });

      if (me) {
        this.authMeData = me;
        this.isAuthenticated = Boolean(me.userId && !me.isAnon);
      } else {
        this.logout();
      }

      return me;
    },

    logout() {
      const profileStore = useProfileStore();
      localStorage.removeItem('auth');
      this.isAuthenticated = false;
      this.authMeData = undefined;
      profileStore.reset();
    },

    async getGuestToken() {
      const authTokens = await apiCallWrapper<AuthTokens>(
        apiAuth,
        {
          method: 'post',
          url: '/token',
          data: {
            authPoint: 'portal',
          },
        },
        {
          default: () => {
            toast.error('');
          },
        },
      );

      if (authTokens?.accessToken) {
        localStorage.setItem('auth', JSON.stringify(authTokens));
      }

      return authTokens;
    },

    async loginByPhone(phone: string) {
      let registered = true;
      await apiCallWrapper(
        apiAuth,
        {
          method: 'post',
          url: '/phone/register',
          data: {
            phone,
            authPoint: 'portal',
          },
        },
        {
          404: () => {
            registered = false;
            toast.error(
              '',
            );
          },
          default: () => {
            registered = false;
            toast.error(
              'Error of authorisation Please contact to Fura administrator',
            );
          },
        },
      );

      return registered;
    },

    async approveBySMS(code: string) {
      const authTokens = await apiCallWrapper<AuthTokens>(
        apiAuth,
        {
          method: 'post',
          url: '/phone/approve',
          data: {
            authPoint: 'portal',
            code,
          },
        },
        {
          default: () => {
            toast.error('Error getting guest token');
          },
        },
      );

      if (!authTokens || !authTokens.accessToken) {
        throw new Error('Error occured while login');
      }

      localStorage.setItem('auth', JSON.stringify(authTokens));

      const authMeData = await this.getAuthMeData();

      if (authMeData && !authMeData.isAnon && authMeData.userId) {
        const profileStore = useProfileStore();
        await profileStore.getProfile();
        return Boolean(profileStore.profile?.id);
      }

      return false;
    },

    setRedirectAfterLogin(redirectAfterLogin: RouteLocationNormalized) {
      this.redirectAfterLogin = { ...redirectAfterLogin };
    },
  },
});

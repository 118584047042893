import { parseISO, format, differenceInCalendarDays } from 'date-fns';
import eu from 'date-fns/locale/en-GB';

const locale = eu;

export function formatDateToTimeText(date: string): string {
  if (!date) {
    return '';
  }

  const parsedDate = parseISO(date);

  return format(parsedDate, 'HH:mm', { locale });
}

export function formatDateToHumanText(date: string): string {
  if (!date) {
    return '';
  }

  const parsedDate = parseISO(date);
  const dateNow = new Date();

  if (format(parsedDate, 'yyyy') === format(dateNow, 'yyyy')) {
    return format(parsedDate, 'dd MMMM', { locale });
  } else {
    return format(parsedDate, 'dd MMMM yyyy', { locale });
  }
}

export function formatDateToHumanTextWithTime(date: string, dontRemoveSameDay?: boolean): string {
  if (!date) {
    return '';
  }

  const parsedDate = parseISO(date);
  const dateNow = new Date();

  if (!dontRemoveSameDay && differenceInCalendarDays(parsedDate, dateNow) === 0) {
    return format(parsedDate, 'HH:mm');
  } else if (format(parsedDate, 'yyyy') === format(dateNow, 'yyyy')) {
    return format(parsedDate, 'dd MMMM, HH:mm', { locale });
  } else {
    return format(parsedDate, 'dd MMMM yyyy HH:mm', { locale });
  }
}

export function formatDateWithTime(date: string): string {
  if (!date) {
    return '';
  }

  const parsedDate = parseISO(date);

  return format(parsedDate, 'dd.MM.yyyy, HH:mm', { locale });
}

export function getTimeZoneOffsetMs(timestamp = Date.now()) {
  return new Date(timestamp).getTimezoneOffset() * 60 * 1000;
}

<template>
  <div class="furaDatePicker">
    <div
      class="wrapper"
      :class="{
        wrapperFocused: isInputFocused,
        wrapperWarning: warning,
        wrapperError: error,
        wrapperWithoutFloatLabel: !floatLabel,
        wrapperDisabled: disabled,
      }"
    >
      <div
        v-if="floatLabel"
        class="label"
        :class="{ labelAbove: valueFiltered || isInputFocused, labelFocused: isInputFocused }"
      >
        {{ floatLabel }}
      </div>

      <date-picker
        ref="datepicker"
        :modelValue="valueFiltered"
        :type="withTime ? 'datetime' : 'date'"
        :format="format"
        v-mask="[mask]"
        :title-format="titleFormat"
        :time-title-format="timeTitleFormat"
        :value-type="localValueType"
        :disabled="disabled"
        :editable="editable"
        :class="{ withoutIcon }"
        :minute-step="minuteStep"
        @update:modelValue="handleInput"
        @change="handleChange"
        @open="handleOpen"
        @close="handleClose"
        @focus="handleFocus"
        @blur="handleBlur"
        append-to-body
        :clearable="!clearButtonHidden"
      />
    </div>

    <div v-if="error && errorMessage" class="errorLabel">{{ errorMessage }}</div>
    <div v-if="warning && warningMessage" class="warningLabel">{{ warningMessage }}</div>
  </div>
</template>

<script>
import { defineComponent, toRefs, ref, computed } from 'vue';
import DatePicker from 'vue2-datepicker';
import { getTimeZoneOffsetMs } from '@/utils/date';

function timestampToISOWithoutTimezone(timestamp, withoutTime) {
  const date = new Date(timestamp);
  return date.toISOString().substring(0, withoutTime ? 10 : 19);
}

export default defineComponent({
  name: 'FUiDatePicker',
  emits: ['update:modelValue', 'change', 'open', 'close'],
  props: {
    modelValue: {},
    titleFormat: {
      type: String,
      default: 'DD.MM.YYYY',
    },
    timeTitleFormat: {
      type: String,
      default: 'DD.MM.YYYY',
    },
    valueType: {
      type: String,
      default: 'timestamp',
    },
    minuteStep: {
      type: Number,
      default: 15,
    },
    floatLabel: {
      type: String,
      default: 'Choose the date',
    },
    warningMessage: {
      type: String,
    },
    errorMessage: {
      type: String,
    },
    withoutIcon: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    warning: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    clearButtonHidden: Boolean,
    withTime: Boolean,
  },
  components: {
    DatePicker,
  },
  setup(props, { emit }) {
    const { modelValue, valueType, withTime } = toRefs(props);
    const isInputFocused = ref(false);
    const datepicker = ref();

    const mask = computed(() => {
      return Array.from(format.value)
        .map(symbol => (['.', ' ', ':'].indexOf(symbol) !== -1 ? symbol : '#'))
        .join('');
    });

    const valueFiltered = computed(() => {
      if (isDateISOString.value && modelValue.value) {
        return new Date(modelValue.value);
      } else if (isStringDateWithoutTimezone.value && modelValue.value) {
        return new Date(modelValue.value).valueOf();
      }
      return modelValue.value || null;
    });

    const isDateISOString = computed(() => {
      return valueType.value === 'iso';
    });

    const isStringDateWithoutTimezone = computed(() => {
      return valueType.value === 'string';
    });

    const localValueType = computed(() => {
      if (isDateISOString.value) {
        return 'date';
      }
      return 'timestamp';
    });

    const format = computed(() => {
      return withTime.value ? 'DD.MM.YYYY HH:mm' : 'DD.MM.YYYY';
    });

    const prepareValueForEmit = value => {
      let preparedValue = value;
      if (isDateISOString.value && value) {
        preparedValue = new Date(value).toISOString();
      } else if (isStringDateWithoutTimezone.value && value) {
        preparedValue = timestampToISOWithoutTimezone(
          value - getTimeZoneOffsetMs(value),
          !withTime.value,
        );
      }
      return preparedValue;
    };

    const handleInput = value => {
      const parsedValue = prepareValueForEmit(value);
      emit('update:modelValue', parsedValue);
    };

    const handleChange = (value, type) => {
      if (type === 'minute') {
        datepicker.value.closePopup();
      }
      const parsedValue = prepareValueForEmit(value);
      emit('change', parsedValue);
    };

    const handleOpen = () => {
      emit('open');
    };

    const handleClose = () => {
      emit('close');
    };

    const handleFocus = () => {
      isInputFocused.value = true;
    };

    const handleBlur = () => {
      setTimeout(() => {
        isInputFocused.value = false;
      }, 100);
    };

    return {
      isInputFocused,
      mask,
      valueFiltered,
      isDateISOString,
      isStringDateWithoutTimezone,
      localValueType,
      format,
      prepareValueForEmit,
      handleInput,
      handleChange,
      handleOpen,
      handleClose,
      handleFocus,
      handleBlur,
      datepicker,
    };
  },
});
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
  padding: 20px 12px 7px 12px;
  box-sizing: border-box;
  border-radius: 6px;
  overflow: hidden;
  height: 52px;
  border: 1px solid var(--grey-light-3);
  background-color: var(--white);
  transition: border-color 0.3s;
  cursor: pointer;

  &:hover:not(.wrapperWarning):not(.wrapperError),
  &.wrapperFocused:not(.wrapperWarning):not(.wrapperError) {
    border: 1px solid var(--black-2);
  }

  &Warning {
    border-color: var(--warning);
  }

  &Error {
    border-color: var(--error);
  }
}

.label {
  position: absolute;
  left: 12px;
  max-width: 100%;
  color: var(--grey-darker);
  line-height: 1;
  background-color: var(--white);
  cursor: text;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  top: 18px;
  transition: all 0.3s ease;
  font-size: 14px;

  &Above {
    font-size: 10px;
    line-height: 1;
    top: 8px;
    color: var(--grey-darker);
    text-transform: uppercase;
  }
}

.withoutIcon :deep(.mx-icon-calendar) {
  display: none;
}

.errorLabel,
.warningLabel {
  font-size: 12px;
  line-height: 16px;
  margin-top: 4px;
}

.errorLabel {
  color: var(--error);
}

.warningLabel {
  color: var(--warning);
}

.wrapper {
  :deep(.mx-icon-calendar),
  :deep(.mx-icon-clear) {
    top: 6px;
    right: 0px;
  }
  :deep(.mx-input-wrapper) {
    font-size: 14px;
    border: 0;
  }
}
</style>

<style lang="scss">
body {
  .mx-datepicker {
    width: 100%;
    font: inherit;
  }

  .mx-input {
    font: inherit;
    padding: 0 30px 0 0;
    height: 24px;
    background-color: transparent;
    border: none;
    box-shadow: none;
    border-radius: 0;
    color: var(--black);
  }

  .mx-datepicker-popup {
    z-index: 30000;
  }
}
</style>

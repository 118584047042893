
import { defineComponent, ref, computed, PropType, onMounted, onBeforeUnmount } from 'vue';
import { TransportationModalRouteTabs, TransportationsRouteTypes } from '@/router/constants';
import { useI18n } from 'vue-i18n';
import { ParsedGlossaries } from '@/stores/almanac';
import {
  formatDateToHumanText,
  formatDateToHumanTextWithTime,
  formatDateWithTime,
} from '@/utils/date';
import { delayToHumanText } from '@/utils/delayToHumanText';
import Monitoring from '@/components/requests/Monitoring.vue';
import DriverAndVehicle from '@/components/requests/DriverAndVehicle.vue';
import Cargo from '@/components/requests/Cargo.vue';
import Modal from '@/components/UI/Modal/Modal.vue';
import Expeditor from '@/components/requests/Expeditor.vue';
import router from '@/router';
import { delay } from '@/utils/delay';
import Map from '@/components/Map/Map.vue';
import { useUserRoles } from '@/hooks/useUserRoles';
import {
  ClientDriverStatus,
  TargetType,
  TraceStatus,
  DriverActivityStatus,
  TargetStatus,
  TransportationFragment,
  TrackFragment,
} from '@/api/generated/portal_api';
import config from '@/../config/config';

const Components = {
  [TransportationModalRouteTabs.monitoring]: Monitoring,
  [TransportationModalRouteTabs.driverAndVehicle]: DriverAndVehicle,
  [TransportationModalRouteTabs.cargo]: Cargo,
  [TransportationModalRouteTabs.expeditor]: Expeditor,
};

export default defineComponent({
  name: 'Request',
  components: { Map, Monitoring, DriverAndVehicle, Cargo, Expeditor, Modal },
  props: {
    transportation: {
      type: Object as PropType<TransportationFragment>,
    },
    tab: {
      type: String as PropType<TransportationModalRouteTabs>,
    },
    glossaries: {
      type: Object as PropType<ParsedGlossaries>,
      required: true,
    },
    transportationFirstLoading: Boolean,
    type: {
      type: String as PropType<TransportationsRouteTypes>,
      required: true,
    },
    driverCurrentPosition: {
      type: Object as PropType<TrackFragment['driverLocation']>,
      required: false,
    },
    loadTransportation: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const { isAdminOrFuraSideExpeditor } = useUserRoles();

    const visible = ref(false);
    const currentTarget = computed(() =>
      props.transportation ? props.transportation.currentTarget : undefined,
    );
    let updateDriverCurrentPositionInterval: number;
    const currentTargetIndex = computed(() => {
      const targets = props.transportation?.targets || [];
      const currentTargetId = props.transportation?.currentTarget?.id || null;
      return targets.findIndex(({ id }) => id === currentTargetId) + 1;
    });

    const driverIsOnline = (status: DriverActivityStatus) =>
      status === DriverActivityStatus.DriverActivityStatusRides ||
      status === DriverActivityStatus.DriverActivityStatusStandsStill;

    const viewRequest = () => {
      window.open(
        `${config.app.url.urlErp}#/requests/${props.transportation.request.id}`,
        '_blank',
      );
    };

    onMounted(() => {
      visible.value = true;

      updateDriverCurrentPositionInterval = setInterval(() => {
        props.loadTransportation();
      }, 60000);
    });

    onBeforeUnmount(() => {
      clearInterval(updateDriverCurrentPositionInterval);
    });

    const tabs = ref<Array<keyof typeof TransportationModalRouteTabs>>([
      'monitoring',
      'driverAndVehicle',
      'cargo',
      'expeditor',
    ]);

    const firstStartRoutePoint = computed(() => props.transportation?.start);
    const lastFinishRoutePoint = computed(() => props.transportation?.finish);

    async function closeModal() {
      visible.value = false;
      await delay(500);
      router.push({
        name: 'transportations',
        params: {
          type: props.type,
        },
      });
    }

    const { t } = useI18n({
      messages: {
        ru: {
          monitoring: 'Monitoring',
          driverAndVehicle: 'Driver and vehicle',
          cargo: 'Item',
          expeditor: 'LSS',
          route: 'Lane',
          requestDates: 'Shipment dates',
          phoneStatus: 'Connection status',
          active: 'Yes',
          unactive: 'No',
          errorRequest: '',
          loading: 'In route to pickup',
          unloading: 'In route to delivery',
          expectedToBeLate: 'Estimated missed appointment on',
          late: 'опоздание на',
          loadingArrived: 'At pickup',
          unloadingArrived: 'At delivery',
          externalNumber: 'Customer’s shipment#',
          driverStatus: 'Driver status',
        },
      },
    });

    return {
      tabs,
      t,
      Components,
      formatDateToHumanText,
      firstStartRoutePoint,
      lastFinishRoutePoint,
      closeModal,
      visible,
      currentTarget,
      TargetType,
      ClientDriverStatus,
      delayToHumanText,
      TraceStatus,
      DriverActivityStatus,
      currentTargetIndex,
      TargetStatus,
      formatDateToHumanTextWithTime,
      formatDateWithTime,
      driverIsOnline,
      viewRequest,
      isAdminOrFuraSideExpeditor,
    };
  },
});

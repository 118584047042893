import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_row_label_value = _resolveComponent("row-label-value")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_row_label_value, {
      label: _ctx.t('type')
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.cargoType ? _ctx.cargoType : _ctx.t('none')), 1)
      ]),
      _: 1
    }, 8, ["label"]),
    (_ctx.transportation.request.cargo.volumeInPallets)
      ? (_openBlock(), _createBlock(_component_row_label_value, {
          key: 0,
          label: _ctx.t('numberOfPallets')
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.round(_ctx.transportation.request.cargo.volumeInPallets, 0)) + " " + _toDisplayString(_ctx.t('pallet')), 1)
          ]),
          _: 1
        }, 8, ["label"]))
      : _createCommentVNode("", true),
    (_ctx.transportation.request.cargo.volumeInCubometres)
      ? (_openBlock(), _createBlock(_component_row_label_value, {
          key: 1,
          label: _ctx.t('volume')
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.round(_ctx.transportation.request.cargo.volumeInCubometres)) + " m3 ", 1)
          ]),
          _: 1
        }, 8, ["label"]))
      : _createCommentVNode("", true),
    (_ctx.transportation.request.cargo.bruttoInTonns)
      ? (_openBlock(), _createBlock(_component_row_label_value, {
          key: 2,
          label: _ctx.t('weight')
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.round(_ctx.transportation.request.cargo.bruttoInTonns)) + " " + _toDisplayString(_ctx.t('tons')), 1)
          ]),
          _: 1
        }, 8, ["label"]))
      : _createCommentVNode("", true)
  ]))
}